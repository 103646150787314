<template>
  <div>
    <p
      :class="allVisitorsPresent ? 'text-green-500' : 'text-red-600'"
      class="text-2xl"
    >
      <b> Present: {{ noOfVisitorsPresent }}/{{ visitors.length }} </b>
      <FontAwesomeIcon
        v-if="allVisitorsPresent"
        class="ml-2"
        :icon="['far', 'check']"
      />
    </p>
    <table class="text-2xl border-0 border-spacing-0">
      <thead class="bg-gray-700">
        <tr>
          <th
            class="p-4 text-white align-left"
            v-for="col in cols"
            :key="col.value"
          >
            {{ col.text }}
          </th>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="visitor in visitors"
          :key="visitor.id"
          :class="visitor.isPresent ? 'bg-green-500' : 'bg-white'"
        >
          <td
            class="px-8 py-4"
            v-for="(header, cellIndex) in cols"
            :key="cellIndex"
            :align="header.align"
            @click="onClick(visitor)"
          >
            <div
              v-if="header.type === 'check'"
              class="whitespace-no-wrap"
            >
              <div
                v-if="visitor[header.value]"
                class="flex items-center justify-center w-5 h-5 text-white bg-green-500 rounded-full"
              >
                <FontAwesomeIcon :icon="['far', 'check']" />
              </div>
              <div
                v-else
                class="flex items-center justify-center w-5 h-5 text-white bg-gray-400 rounded-full"
              >
                <FontAwesomeIcon :icon="['fat', 'circle']" />
              </div>
            </div>
            <div v-else>
              {{ visitor[header.value] }}
            </div>
          </td>
          <td class="px-8 py-4">
            <a
              :href="'tel:' + visitor.phone"
              v-if="!visitor.isPresent"
              class="text-blue-600 underline"
            >
              <FontAwesomeIcon :icon="['far', 'phone']" />
              Call
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import http from "@/utilities/http";

export default {
  data() {
    return {
      visitors: [],
    };
  },
  computed: {
    cols() {
      return [
        { value: "name", text: "Name" },
        { value: "phone", text: "Phone no." },
      ];
    },
    allVisitorsPresent() {
      return this.visitors.length
        ? this.visitors.every((visitor) => visitor.isPresent)
        : false;
    },
    noOfVisitorsPresent() {
      return this.visitors.filter((visitor) => visitor.isPresent).length;
    },
  },
  methods: {
    onClick(visitor) {
      console.debug("this is row click for visitor", visitor);
      visitor.isPresent = !visitor.isPresent;
    },
  },
  mounted() {
    http.get("visitors").then((data) => {
      this.visitors = data.map((visitor) => {
        visitor.isPresent = false;
        return visitor;
      });
    });
  },
};
</script>

<style>
.bg {
  background-size: cover;
  background-position: top right;
}
</style>